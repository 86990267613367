import axios from 'axios';

export const getFunctions = async (file) => {
    const { status, data } =
        process.env.REACT_APP_PRODUCTION === 'development'
            ? await axios
                .get(`/funciones-dev.json?v=${new Date().getTime()}`)
                .catch(error => {
                    console.log(error.response);
                })
            : await axios.get(
                `${
                    process.env.REACT_APP_URL_IMAGES
                }${file}.json?v=${new Date().getTime()}`
            );
    if (status === 200) {
        return data;
    } else {
        return null;
    }
};
export const getPosition = async (uuid, suf = '') => {
    const { status, data } = await axios.get(
        `https://throttle.ticketmundo.live/api/throttle?uuid=${uuid}&resource=${process.env.REACT_APP_NAME_COLA}-${suf}`
        // `https://toolkit.ticketmundo.com/api/throttle?uuid=${uuid}&resource=${process.env.REACT_APP_NAME_COLA}-${suf}`
    );
    if (status === 200) {
        return data;
    } else {
        return null;
    }
};

export const consultPosition = async host => {
    const { status, data } = await axios.get(
        `https://throttle.ticketmundo.live/api/throttle?host=${host}`
    );
    if (status === 200) {
        return data;
    } else {
        return null;
    }
};
